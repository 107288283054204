<template>
  <div
    class="rounded-lg cursor-pointer relative"
    :class="{ [`border-open`]: hasBorder && is_open }"
    :style="`--borderColor: ${borderColor};`"
  >
    <nuxt-link
      v-if="to"
      tag="h3"
      :to="to"
      :active-class="openClass"
      class="flex items-center justify-between px-5 py-4 transition duration-300 mb-px rounded-lg font-medium"
    >
      <span>{{ title }}</span>
      <arrow-small class="stroke-current"></arrow-small>
    </nuxt-link>
    <div
      class="flex items-center justify-between px-5 py-4 transition duration-300 mb-px"
      :class="is_open ? openClass : closedClass"
      @click="toggle()"
      v-else
    >
      <slot :open="is_open" :title="title" name="title">
        <h3>{{ title }}</h3>
      </slot>
      <slot :open="is_open" name="icon">
        <arrow-small
          class="transition stroke-current duration-300"
          :class="is_open ? '-rotate-90' : 'rotate-90'"
        ></arrow-small>
      </slot>
    </div>
    <div
      class="transition-height duration-300 overflow-hidden"
      :class="{ 'max-h-9999px': is_open, 'max-h-0': !is_open }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ArrowSmall from "~/assets/svg/arrow-small.svg";
import ComputeViewportWith from "~/mixins/compute-viewport-width";

export default {
  name: "ListAccordion",
  setup() {
    const nuxtApp = useNuxtApp();
    const instance = getCurrentInstance();
    const uuid = ref(instance.uid);
    return { uuid, nuxtApp };
  },
  mixins: [ComputeViewportWith],
  components: {
    ArrowSmall,
  },
  props: {
    to: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      required: false,
      default: "",
    },
    openClass: {
      type: String,
      required: false,
      default: "",
    },
    closedClass: {
      type: String,
      required: false,
      default: "bg-transparent",
    },
    hasBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderColor: {
      type: String,
      required: false,
      default: "",
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnRouteChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    preventToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    groupClose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      is_open: this.open,
    };
  },
  watch: {
    $route() {
      if (this.closeOnRouteChange) {
        if (this.viewport_width < 1024) {
          this.is_open = false;
        }
      }
    },
  },
  methods: {
    toggle() {
      this.nuxtApp.$emit("close", this.uuid);
      if (this.preventToggle === false) {
        this.is_open = !this.is_open;
      }
    },
  },
  mounted() {
    this.nuxtApp.$on("close", (id) => {
      if ((this.uuid !== id && this.viewport_width > 1024) || (this.uuid !== id && this.groupClose)) {
        this.is_open = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.max-h-9999px {
  max-height: 9999px;
}

._border {
  border-width: 1px;
}
</style>
