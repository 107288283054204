<template>
  <div class="sticky top-100px lg:max-w-342px lg:flex flex-wrap justify-center mr-auto lg:mr-14">
    <div class="text-1.375rem hidden lg:flex rounded-t-lg h-16 font-bold items-center w-full" :class="headerClass">
      <slot name="header">
        <span class="uppercase pl-7.5">{{ title }}</span>
      </slot>
    </div>
    <!-- Mobile version -->
    <div class="flex w-full lg:hidden">
      <list-accordion
        :title="filteredItems[0].title"
        class="w-full my-7.5 mx-8 md:mx-18 border bg-white lg:my-0 lg:mx-2.5 lg:border-0 rounded-b-0 overflow-hidden"
        :open-class="mobileCategoryOpenClass"
        :closed-class="mobileCategoryClosedClass"
        has-border
        close-on-route-change
        :border-color="borderColor"
      >
        <template #title="{ title }">
          <h3 class="font-bold">{{ title }}</h3>
        </template>
        <div class="flex" v-if="route.name === `quit___${locale}`">
          <slot name="prepend"></slot>
        </div>
        <nuxt-link
          :to="filteredItems[0].link"
          class="flex items-center justify-between pl-7.5 pr-5 py-4 transition duration-300 font-medium mb-px text-sm"
          :class="mobileCategoryClosedClass"
          :exact-active-class="mobilePostActiveClass"
          v-if="filteredItems[0].posts.length > 0"
        >
          {{ t("see-all") }}
        </nuxt-link>
        <nuxt-link
          v-for="post in filteredItems[0].posts"
          :key="post.id"
          :to="post.link"
          class="flex items-center justify-between pl-7.5 pr-5 py-4 transition duration-300 font-medium mb-px text-sm"
          :class="mobileCategoryClosedClass"
          :exact-active-class="mobilePostActiveClass"
        >
          {{ post.title }}
        </nuxt-link>
        <!-- 2e accordion -->
        <list-accordion
          v-for="category in filteredItems.slice(1)"
          :groupClose="true"
          :key="category.id"
          :title="category.title"
          class="bg-white mb-px lg:hidden"
          :open-class="mobileCategoryOpenClass"
          :to="hasCustomLink(category)"
        >
          <template #title="{ open, title }">
            <h3 :class="open ? 'font-bold' : 'font-medium'" style="max-width: 95%">{{ title }}</h3>
          </template>
          <nuxt-link
            :to="category.link"
            class="flex items-center justify-between pl-7.5 pr-5 py-4 transition duration-300 font-medium mb-px text-sm"
            :class="mobileCategoryClosedClass"
            :exact-active-class="mobilePostActiveClass"
            v-if="category.posts.length > 0"
          >
            {{ t("see-all") }}
          </nuxt-link>
          <nuxt-link
            v-for="post in category.posts"
            :key="post.id"
            :to="post.link"
            class="flex items-center justify-between pl-7.5 pr-5 py-4 transition duration-300 font-medium mb-px text-sm"
            :class="mobileCategoryClosedClass"
            :exact-active-class="mobilePostActiveClass"
            :target="post.blank ? '_blank' : ''"
          >
            {{ post.title }}
          </nuxt-link>
        </list-accordion>
        <div class="flex" v-if="route.name !== `quit___${locale}`">
          <slot name="prepend"></slot>
        </div>
      </list-accordion>
    </div>
    <!-- Desktop version -->
    <div class="hidden lg:flex flex-wrap w-full lg:bg-white pb-2.5 rounded-b-lg">
      <slot name="prepend"></slot>
      <list-category
        v-for="item in items"
        :key="item.id"
        :to="item.link"
        :title="item.title"
        :hover-class="categoryHoverClass"
        :active-class="categoryActiveClass"
        :is-open="route.path.includes(item.link) || (item.id === 405 && isHelpRoute)"
        :border-color="borderColor"
        :hide-arrow="item.id === 114"
      >
        <nuxt-link
          v-for="post in item.posts"
          :key="post.id"
          :to="post.link"
          :class="postHoverClass"
          class="flex items-center justify-between pl-7.5 pr-5 py-4 transition duration-300 mt-px font-medium text-sm"
          :active-class="postActiveClass"
          :target="post.blank ? '_blank' : ''"
        >
          {{ post.title }}
        </nuxt-link>
      </list-category>
    </div>
  </div>
</template>

<script>
import { clone } from "radash";
import ListAccordion from "~/components/Lists/ListAccordion.vue";
import ListCategory from "~/components/Lists/ListCategory.vue";

export default {
  name: "SectionCategorySidebar",
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    headerClass: {
      type: String,
      required: true,
    },
    categoryHoverClass: {
      type: String,
      required: true,
    },
    postHoverClass: {
      type: String,
      required: true,
    },
    categoryActiveClass: {
      type: String,
      required: true,
    },
    postActiveClass: {
      type: String,
      required: true,
    },
    mobileCategoryOpenClass: {
      type: String,
      required: false,
    },
    mobileCategoryClosedClass: {
      type: String,
      required: false,
    },
    mobilePostActiveClass: {
      type: String,
      required: false,
    },
    borderColor: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    const route = useRoute();
    return { t, locale: locale.value, localePath, route };
  },
  components: {
    ListAccordion,
    ListCategory,
  },
  computed: {
    // C'ette fonction est là a cause d'une particularité de la section d'aide
    // La structure d'url n'est pas la même et cause un bug où le menu se ferme
    isHelpRoute() {
      const route = this.route.path;
      return (
        route === this.localePath("quit-subpage-index-help-phone") ||
        route === this.localePath("quit-subpage-index-help-person") ||
        route === this.localePath("quit-subpage-index-help-sms")
      );
    },
    filteredItems() {
      const items = clone(this.items);
      if (this.route.path === this.localePath("quit")) {
        items.unshift({
          title: this.t("quit"),
          posts: [],
        });
      }

      return items.sort((i) => (this.route.path.includes(i.link) ? -1 : 1));
    },
  },
  methods: {
    hasCustomLink(category) {
      if (category.id === 114) {
        return this.localePath({ name: "information-subpage-index-category-news" });
      }

      // Le jour où ils vont rajouter des items, ca se deviendra inutile
      if (category.id === 117 && category.posts.length === 0) {
        return category.link;
      }

      if (category.id === 407) {
        return category.link;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-1\.375rem {
  font-size: 1.375rem;
}

.lg\:max-w-342px {
  @media (min-width: 1024px) {
    max-width: 342px;
  }
}

// Eh là là... changement du client qui sort du style guide
// Rendu là trop paresseux pour refactor, yer tard.
.text-sm {
  font-size: 15px !important;
}
</style>
<i18n lang="json">
{
  "fr": {
    "quit": "J'arrête",
    "see-all": "Voir tout"
  },
  "en": {
    "quit": "I quit now",
    "see-all": "See all"
  }
}
</i18n>
