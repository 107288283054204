<template>
  <div
    class="cursor-pointer relative w-full mt-px"
    :class="{ [`border-open`]: isOpen }"
    :style="`--borderColor: ${borderColor};`"
  >
    <nuxt-link
      :to="to"
      :class="{ [hoverClass]: !isOpen, [activeClass]: isOpen }"
      class="flex items-center justify-between px-5 py-4 transition duration-300 mt-px font-medium uppercase"
    >
      <span>{{ title }}</span>
      <arrow-small class="stroke-current arrow ml-2 !min-w-[1rem]" v-if="!isOpen && !hideArrow"></arrow-small>
    </nuxt-link>
    <div class="transition-height duration-300 overflow-hidden" :class="{ 'max-h-9999px': isOpen, 'max-h-0': !isOpen }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ArrowSmall from "~/assets/svg/arrow-small.svg";
import ComputeViewportWith from "~/mixins/compute-viewport-width";

export default {
  name: "ListCategory",
  mixins: [ComputeViewportWith],
  components: {
    ArrowSmall,
  },
  props: {
    to: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      required: false,
      default: "",
    },
    hoverClass: {
      type: String,
      required: false,
      default: "",
    },
    activeClass: {
      type: String,
      required: false,
      default: "",
    },
    borderColor: {
      type: String,
      required: false,
      default: "",
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.max-h-9999px {
  max-height: 9999px;
}

._border {
  border-width: 1px;
}

.border-open {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    border: 1px solid var(--borderColor);
  }
}
</style>
